<template>
  <div class="view-onboarding-phone">
    <pinboard
      v-model="pin"
      @input="key++"
      class="onboarding__pinboard"
      input-visible
    />

    <span
      v-if="pin.length === 5 && codeError"
      class="phone__error"
    >{{ $t('conn3ct-wallet.phone_verify_invalid_token') }}</span>

    <actions-button
      :appearance="$pepper.Appearance.DEFAULT"
      class="onboarding__action"
      :disabled="resend"
      :loading="$user.loading"
      :size="$pepper.Size.L"
      @click="onResend"
    >{{ $t('conn3ct-wallet.resend_phone_action') }}</actions-button>

    <actions-button
      :appearance="$pepper.Appearance.PRIMARY"
      class="onboarding__action"
      :disabled="pin.length !== 5"
      :loading="$user.loading"
      :size="$pepper.Size.L"
      @click="onVerify"
    >{{ $t('conn3ct-wallet.verify_phone_action') }}</actions-button>

    <button
      @click="onCancel"
      class="view-onboarding-phone__cancel"
    >{{ $t('conn3ct-wallet.cancel') }}</button>
  </div>
</template>

<script>
import Pinboard from '@/components/forms/pinboard.vue'

export default {
  name: 'SaylConn3ctPhone',

  components: {
    Pinboard,
  },

  data() {
    return {
      resend: false,
      loading: false,
      pin: [],
      key: 0,
      codeError: false,
      isOptional: false
    }
  },

  watch: {
    pin(newVal, oldVal) {
      if(newVal.length < 5 && oldVal.length === 5) this.codeError = false
    }
  },

  methods: {
    async init() {
      try {
        this.loading = true
        let user = await this.$user.getUser()
        this.setLocale(user.lang)
        if(this.$basil.get(this.$user, 'user.phoneVerified')) {
          this.$router.replace({ name: 'sayl-connect_onboarding-pin' }).catch(() => {})
        }
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    onCancel() {
      this.$emit('next', this.isOptional)
    },

    async onVerify() {
      this.codeError = false

      try {
        await this.$user.verifyPhone({ code: this.pin.join('') })
        this.$router.replace({ name: 'sayl-connect_onboarding-pin' }).catch(() => {})
      } catch(e) {
        this.codeError = true
        $console.error(e)
      }
    },

    onResend() {
      this.$user.resendVerificationPhone()
        .then(() => {
          this.resend = true
        })
        .catch((e) => $console.error(e))
        .finally(() => setTimeout(() => this.resend = false, 30 * 1000))
    },
  },

  created() {
    this.isOptional = this.$route.params?.isOptional ?? false
  },

  mounted() {
    window.scrollTo(0, 0)
    this.init()
  }
}
</script>

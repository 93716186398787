<script>
export default {
  name: 'PinBoard',

  props: {
    value: Array,
    inputVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pin: '',
      validValues: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],

      focus: 0,
    }
  },

  watch: {
    value(val) {
      this.pin = val.join('')
      this.focus = val.length === 5 ? 4 : val.length
    },

    pin(val) {
      if(val.length > 5) {
        this.pin = val.slice(0, 5)
      }
      this.focus = this.pin.length === 5 ? 4 : this.pin.length
      this.$emit('input', this.pin.split(''))
    }
  },

  methods: {
    showKeyboard() {
      this.$refs.pinboard.focus()
      this.focus = this.pin.length
    },

    scroll() {
      setTimeout(() => document.body.scrollIntoView(false), 1000)
    },
  },

  mounted() {
    this.pin = this.value.join('')
    this.showKeyboard()
  },

  updated() {
    this.showKeyboard()
  }
}
</script>

<template>
  <div class="forms-pinboard">
    <input
      v-model="pin"
      @focus="scroll"
      class="forms-pinboard__hidden-input"
      type="number"
      min="0"
      ref="pinboard"
      autofocus
      pattern="[0-9]*"
    />

    <div
      v-for="(_, i) in 5"
      @click="showKeyboard()"
      class="forms-pinboard__input"
      :class="{
        '-focus': i === focus,
      }"
      :key="i"
      type="password"
    >
      <div
        v-if="!$basil.isNil(pin[i]) && !inputVisible"
        class="point"
      ></div>

      <div
        v-else-if="!$basil.isNil(pin[i])"
        class="value text-white"
      >{{ pin[i] }}</div>
    </div>
  </div>
</template>
